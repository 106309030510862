import React from 'react';
import Container from 'react-bootstrap/Container';
import MDLogo from '../images/logo.png';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import styled from 'styled-components';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';

const StyledLogo = styled.img`
  width: 50px;
  margin-right: 1.5em;
  @media (min-width: 426px) {
    width: 80px;
  }
`;

const StyledTagline = styled.span`
  flex-grow: 1;
  flex-basis: 50%;

  svg {
    color: rgba(239, 154, 33, 1);
    margin-top: -4px;
  }
  a {
    color: #000;
  }
  @media (max-width: 426px) {
    display: none;
  }
`;

const StyledLanguage = styled.span`
  flex-basis: 10%;
`;

const Header: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  return (
    <header className="App-header">
      <Container>
        <StyledLogo
          src={MDLogo}
          alt={intl.formatMessage({ id: 'app.logo.alt' })}
        />
        <StyledTagline>
          <a
            href={intl.formatMessage({ id: 'nav.tagline.url' })}
            target="_blank"
            rel="noreferrer noopener"
            hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
          >
            <FormattedMessage id="nav.tagline" /> <ArrowRightIcon />
          </a>
        </StyledTagline>

        <a
          href={process.env.REACT_APP_ALT_LANG_DOMAIN}
          rel="alternate"
          hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
        >
          <StyledLanguage>
            <FormattedMessage id="nav.language" />
          </StyledLanguage>
        </a>
      </Container>
    </header>
  );
};

export default injectIntl(Header);
