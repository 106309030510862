import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { CommentsPayload } from './Comment';

const REMOTE_URL = process.env.REACT_APP_COMMENTS_URL || '';

const handleError = (error: any) => {
  if (error.isAxiosError && error.response && error.response.data) {
    const errorMessage = `error code: ${JSON.stringify(
      error.response.data
    )}, status: ${error.response.status}`;
    Sentry.captureMessage(errorMessage, Sentry.Severity.Error);
  }
  Sentry.captureException(error);
  throw error;
};

export const getComments = async (locale: string) => {
  try {
    const result = await axios.get(REMOTE_URL);

    return result.data as CommentsPayload;
  } catch (error) {
    handleError(error);
  }
};
