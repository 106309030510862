import React from 'react';
import footerLogoEN from '../images/footer-logo.png';
import footerLogoFR from '../images/footer-logo-fr.png';
import footerLogoEN2x from '../images/footer-logo@2x.png';
import footerLogoFR2x from '../images/footer-logo-fr@2x.png';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import styled from 'styled-components';

const Logo = styled.img`
  height: 50px;
  margin-top: 20px;

  margin-right: 1.5em;

  @media (min-width: 768px) {
    height: 80px;
    margin: 1.5rem 0;
  }
`;

const FooterLogo: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const logo = intl.locale === 'fr' ? footerLogoFR : footerLogoEN;
  const logo2x = intl.locale === 'fr' ? footerLogoFR2x : footerLogoEN2x;
  return (
    <Logo
      src={logo}
      alt={intl.formatMessage({ id: 'footer.logo.alt' })}
      srcSet={`${logo2x} 2x`}
    />
  );
};

export default injectIntl(FooterLogo);
