import axios from 'axios';
import * as Sentry from '@sentry/browser';

export interface Font {
  displayName: string;
  cssName: string;
}

const handleError = (error: any) => {
  Sentry.captureException(error);
  throw error;
};

export const getFonts = async () => {
  try {
    const result = await axios.get(process.env
      .REACT_APP_FONTS_API_URL as string);
    return result.data;
  } catch (error) {
    handleError(error);
  }
};
