import React from 'react';
// import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { Comment } from '../api/Comment';
// import styled from 'styled-components';

// const TitleTY = styled.h2`
//   width: 100%;
//   display: block;
//   border-bottom: 1px solid #000;
// `;

interface CarouselBlockProps {
  testimonials: Comment[];
}

const getTestimonialItems = (testimonials: Comment[]) => {
  const testimonialItems = testimonials.map(testimonial => {
    return (
      <Carousel.Item key={testimonial.id}>
        <p
          dangerouslySetInnerHTML={{
            __html: testimonial.content.rendered,
          }}
        ></p>
      </Carousel.Item>
    );
  });
  return testimonialItems;
};

const CarouselBlock: React.FunctionComponent<CarouselBlockProps> = props => {
  return (
    <div className="mt-4 pt-4 pt-lg-0">
      <Carousel
        className="testimonialCarousel"
        indicators={false}
        nextLabel="Next"
        prevLabel="Previous"
      >
        {getTestimonialItems(props.testimonials)}
      </Carousel>
    </div>
  );
};

export default CarouselBlock;
