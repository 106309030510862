import React, { createRef } from 'react';
// import CTACard from './components/CTACard';
import { saveTestimonial, saveSignature } from './api/Wordpress';
import { getComments } from './api/Rest';
import { Comment, CommentType } from './api/Comment';
import './styles/App.scss';
import './styles/Animation.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import TestimonialForm, {
  TestimonialFormValues,
} from './components/TestimonialForm';
import SignatureForm, { SignatureFormValues } from './components/SignatureForm';
import SignatureConfirmation from './components/SignatureConfirmation';
import TestimonialConfirmation from './components/TestimonialConfirmation';
import CarouselBlock from './components/CarouselBlock';
import VideoBlock from './components/VideoBlock';
import styled from 'styled-components';
import {
  injectIntl,
  InjectedIntlProps,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import Button from 'react-bootstrap/Button';
import CSSTransition from 'react-transition-group/CSSTransition';
import { Font, getFonts } from './api/Font';
import { AxiosError } from 'axios';
import SignatureBook from './components/SignatureBook';
import Modal from './components/Modal';
import ShareVideoImageEN from './images/video-en.png';
import ShareVideoImageFR from './images/video-fr.png';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

type ErrorMessageType =
  | 'signatureErrorMessage'
  | 'testimonialErrorMessage'
  | 'fontsErrorMessage'
  | 'commentsErrorMessage';

type AppErrorState = {
  [key in ErrorMessageType]: string;
};

type AppState = {
  testimonials: Comment[];
  signatures: Comment[];
  fonts: Font[];
  isLoading: boolean;
  hasSavedSignature: boolean;
  hasSavedTestimonial: boolean;
  isModalActive: boolean;
  isDisclaimerOpen: boolean;
};

interface AppProps {
  hideLoader?: () => void;
  showLoader?: () => void;
}

const StyledCTABlockIntro = styled.div`
  padding: 2.5em 1.5em;
  text-align: center;
`;

const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
  // General scroll to element function
  if (ref && ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

// Using union (|) here instead of & intersection to get around no TS support for computed property names for Interfaces or Classes
class App extends React.Component<
  AppProps & InjectedIntlProps,
  AppState | AppErrorState
> {
  state = {
    testimonials: [] as Comment[],
    signatures: [] as Comment[],
    fonts: [] as Font[],
    isLoading: true,
    signatureErrorMessage: '',
    testimonialErrorMessage: '',
    fontsErrorMessage: '',
    commentsErrorMessage: '',
    hasSavedSignature: false,
    hasSavedTestimonial: false,
    isModalActive: false,
    isDisclaimerOpen: false,
  };
  signatureFormRef = createRef<HTMLDivElement>();
  testimonialFormRef = createRef<HTMLDivElement>();
  videoRef = createRef<HTMLDivElement>();

  handleAxiosError(error: AxiosError, commentType: CommentType) {
    if (error.response) {
      // console.log(error.response.status); // 409
      switch (error.response.data.code) {
        case 'comment_duplicate':
          // treat duplicate submissions as success
          if (commentType === 'signature') {
            this.setState({
              hasSavedSignature: true,
            });
          } else {
            this.setState({
              hasSavedTestimonial: true,
            });
          }
          break;
        default:
          this.setState({
            [commentType + 'ErrorMessage']: this.props.intl.formatMessage({
              id: 'error.generic',
            }),
          });
      }
    }
  }

  handleError(error: any, commentType: CommentType) {
    if (error.isAxiosError) {
      this.handleAxiosError(error, commentType);
    } else {
      this.setState({
        [commentType + 'ErrorMessage']: this.props.intl.formatMessage({
          id: 'error.generic',
        }),
      });
    }
  }

  handleTestimonialSubmit = async (value: TestimonialFormValues) => {
    // console.log(`submitting to testimonial api`);
    try {
      await saveTestimonial(value.testimonial);
      // console.log(`save testimonial result from api`);
      // console.log(postResult);
      this.setState({ hasSavedTestimonial: true });
    } catch (error) {
      this.handleError(error, 'testimonial');
    }
  };

  handleSignatureSubmit = async (value: SignatureFormValues) => {
    // console.log(`submitting to signature api`);
    try {
      await saveSignature(value.name, value.signatureStyle);
      this.setState({ hasSavedSignature: true });
      // console.log(`save signature result from api`);
      // console.log(postResult);
      window.gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to:
          process.env.REACT_APP_GOOGLE_TAG_ID_1 + '/md50c0/md_md00+standard',
      });
    } catch (error) {
      this.handleError(error, 'signature');
    }
  };

  handleSeeCardClick = () => {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to:
        process.env.REACT_APP_GOOGLE_TAG_ID_1 + '/md50c0/md_md000+standard',
    });
  };

  handleSeeAllSignaturesClick = () => {
    this.setState({ isModalActive: true });
  };

  async componentDidMount() {
    try {
      const fonts = await getFonts();
      if (fonts) {
        this.setState({ fonts });
      }
    } catch (error) {
      this.setState({
        fontsErrorMessage: error,
        isLoading: false,
      });
    }

    try {
      const result = await getComments(this.props.intl.locale);
      if (result) {
        this.setState({
          testimonials: result.testimonials,
          signatures: result.signatures,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        commentsErrorMessage: error,
        isLoading: false,
      });
    }

    this.props.hideLoader && this.props.hideLoader();
  }

  getSignatureFormSection = (isInvertedColorScheme: boolean) => {
    return (
      <div>
        <CSSTransition
          in={this.state.hasSavedSignature}
          timeout={500}
          classNames="zoom"
          unmountOnExit
          appear
        >
          <SignatureConfirmation />
        </CSSTransition>
        <CSSTransition
          in={!this.state.hasSavedSignature}
          timeout={300}
          classNames="zoom"
          unmountOnExit
          appear
        >
          <SignatureForm
            onSubmit={this.handleSignatureSubmit}
            errorMessage={this.state.signatureErrorMessage}
            fontsErrorMessage={this.state.fontsErrorMessage}
            fonts={this.state.fonts}
            isInvertedColorScheme={isInvertedColorScheme}
            onButtonClick={() => scrollToRef(this.videoRef)}
          />
        </CSSTransition>
      </div>
    );
  };

  getTestimonialFormSection = () => {
    return (
      <div>
        <CSSTransition
          in={this.state.hasSavedTestimonial}
          timeout={500}
          classNames="zoom"
          unmountOnExit
          appear
        >
          <TestimonialConfirmation />
        </CSSTransition>
        <CSSTransition
          in={!this.state.hasSavedTestimonial}
          timeout={300}
          classNames="zoom"
          unmountOnExit
          appear
        >
          <TestimonialForm
            onSubmit={this.handleTestimonialSubmit}
            errorMessage={this.state.testimonialErrorMessage}
          />
        </CSSTransition>
      </div>
    );
  };

  getLocalizedAsset = (enAsset: any, frAsset: any) => {
    const asset = this.props.intl.locale === 'fr' ? frAsset : enAsset;
    const assetFullUrl = process.env.REACT_APP_LOCAL_DOMAIN + asset;
    return assetFullUrl;
  };

  activateModal = () => {
    this.setState({ isModalActive: true });
  };

  handleModalExit = () => {
    this.setState({ isModalActive: false });
  };

  render() {
    // console.info(this.state);
    const { intl } = this.props;

    return (
      <div className="App">
        <Header />
        <main role="main">
          <Hero onButtonClick={() => scrollToRef(this.signatureFormRef)}>
            {' '}
            <Button
              onClick={this.handleSeeAllSignaturesClick}
              className="my-3"
              variant="secondary"
              type="button"
              id="viewSignaturesButton"
            >
              <FormattedMessage id="form.card.view.list" />
              <ArrowRightIcon />
            </Button>
          </Hero>

          <Container className="mt-4">
            <Container className="CTA-wrapper">
              <StyledCTABlockIntro>
                <h2 className="App-subtitle">
                  <FormattedHTMLMessage id="ctaBlock.title" />
                </h2>

                <hr className="divider" />
                <VideoBlock />
                <div id="videoSocialButtons">
                  <div
                    className="sharethis-inline-share-buttons my-2 text-center"
                    data-title={intl.formatMessage({
                      id: 'sharethis.video.title',
                    })}
                    data-image={this.getLocalizedAsset(
                      ShareVideoImageEN,
                      ShareVideoImageFR
                    )}
                  />
                </div>
              </StyledCTABlockIntro>
            </Container>

            <div className="my-3 p-3 p-lg-5">
              <Row className="mt-4">
                <Col>
                  <h2 className="App-subtitle">
                    <FormattedMessage id="form.testimonial.title" />
                  </h2>
                  <hr className="divider" />
                  <CarouselBlock testimonials={this.state.testimonials} />
                </Col>
              </Row>
            </div>
          </Container>
        </main>
        <Footer />
        <Modal
          isMounted={this.state.isModalActive}
          applicationNodeId="root"
          onExit={this.handleModalExit}
        >
          <SignatureBook signatures={this.state.signatures} />
        </Modal>
      </div>
    );
  }
}

export default injectIntl(App);
