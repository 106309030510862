/**
 * Returns the input string with html tags removed
 * @param input
 */
export function removeHTMLTags(input: string): string {
  const regex = /(<([^>]+)>)/gi;
  const result = input.replace(regex, '');

  return result;
}
