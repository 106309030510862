import React from 'react';
import Button from 'react-bootstrap/Button';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import StyledError from './StyledError';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  injectIntl,
  InjectedIntlProps,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import LoadingIndicator from './LoadingIndicator';

interface TestimonialFormProps {
  onSubmit: (value: TestimonialFormValues) => void;
  errorMessage: string;
}

export interface TestimonialFormValues {
  testimonial: string;
  hasTestimonialConsent: boolean;
}

const initialValues: TestimonialFormValues = {
  testimonial: '',
  hasTestimonialConsent: false,
};

class TestimonialForm extends React.Component<
  TestimonialFormProps & InjectedIntlProps
> {
  testimonialSchema = Yup.object().shape({
    testimonial: Yup.string()
      .required(
        this.props.intl.formatMessage({
          id: 'form.testimonial.field.validation.story',
        })
      )
      .test(
        'testimonial-is-short',
        this.props.intl.formatMessage({
          id: 'form.testimonial.field.validation.story.length',
        }),
        (value: string) => {
          if (value) {
            const words = value.match(/\S+/g) || [];
            return words.length <= 500;
          }
          return true;
        }
      ),
    hasTestimonialConsent: Yup.bool()
      .test(
        'hasTestimonialConsent',
        this.props.intl.formatMessage({
          id: 'form.testimonial.field.validation.hasTestimonialConsent',
        }),
        value => value === true
      )
      .required(
        this.props.intl.formatMessage({
          id: 'form.testimonial.field.validation.hasTestimonialConsent',
        })
      ),
  });

  handleSubmit = async (
    values: TestimonialFormValues,
    actions: FormikActions<TestimonialFormValues>
  ) => {
    //process form submission here
    await this.props.onSubmit({
      testimonial: values.testimonial,
      hasTestimonialConsent: values.hasTestimonialConsent,
    });
    //done submitting, set submitting to false
    actions.setSubmitting(false);
  };

  renderForm = (formikProps: FormikProps<TestimonialFormValues>) => {
    const words = formikProps.values.testimonial.match(/\S+/g) || [];
    const wordsCount = words.length;

    return (
      <Form>
        {this.props.errorMessage && (
          <StyledError message={this.props.errorMessage} />
        )}
        <div className="form-group">
          <label htmlFor="testimonial" className="sr-only">
            <FormattedMessage id="form.testimonial.field.story" />
          </label>
          <Field
            className="form-control"
            component="textarea"
            name="testimonial"
            id="testimonial"
            placeholder={this.props.intl.formatMessage({
              id: 'form.testimonial.field.story',
            })}
            disabled={formikProps.isSubmitting}
          />
          {wordsCount}/500
          <ErrorMessage name="testimonial" component={StyledError} />
        </div>

        <div className="form-check mb-3">
          <label className="form-check-label">
            <Field
              className="form-check-input"
              type="checkbox"
              name="hasTestimonialConsent"
              checked={formikProps.values.hasTestimonialConsent}
              disabled={formikProps.isSubmitting}
            />
            <FormattedHTMLMessage id="form.testimonial.field.hasTestimonialConsent" />
          </label>
          <ErrorMessage name="hasTestimonialConsent" component={StyledError} />
        </div>

        <Button
          id="testimonialSubmitButton"
          type="submit"
          variant="secondary"
          disabled={formikProps.isSubmitting}
          style={{ minHeight: '38px' }}
        >
          <FormattedMessage id="form.testimonial.submitButton" />{' '}
          {!formikProps.isSubmitting && <ArrowRightIcon />}
          {formikProps.isSubmitting && <LoadingIndicator color="#ef9a21" />}
        </Button>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={this.testimonialSchema}
        onSubmit={this.handleSubmit}
        render={(formikProps: FormikProps<TestimonialFormValues>) => {
          return this.renderForm(formikProps);
        }}
      />
    );
  }
}

export default injectIntl(TestimonialForm);
