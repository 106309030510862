import React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { FormattedMessage } from 'react-intl';

const SignatureConfirmation: React.FunctionComponent = () => {
  return (
    <p className="text-success font-weight-bold">
      <CheckCircle />
      <FormattedMessage id="form.card.confirmation">
        {(...content) => (
          <span id="signatureSubmittedConfirmation">{content}</span>
        )}
      </FormattedMessage>
    </p>
  );
};

export default SignatureConfirmation;
