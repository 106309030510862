import React, { FunctionComponent } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import '../styles/Animation.scss';

const StyledError: FunctionComponent<{ message?: string }> = props => {
  return (
    <CSSTransition
      timeout={500}
      classNames="fade"
      unmountOnExit
      appear
      in={true}
    >
      <div className="error">
        {props.message && (
          <span dangerouslySetInnerHTML={{ __html: props.message }}></span>
        )}

        {props.children}
      </div>
    </CSSTransition>
  );
};

export default StyledError;
