import React from 'react';
import Button from 'react-bootstrap/Button';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import StyledError from './StyledError';
import StyledSelect from './StyledSelect';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  InjectedIntlProps,
  injectIntl,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import LoadingIndicator from './LoadingIndicator';
import { Font } from '../api/Font';

interface SignatureFormProps {
  onSubmit: (value: SignatureFormValues) => void;
  errorMessage: string;
  fontsErrorMessage: string;
  fonts: Font[];
  isInvertedColorScheme: boolean;
  onButtonClick: () => void;
}

interface SignatureFormState {
  name: string;
}

export interface SignatureFormValues {
  name: string;
  signatureStyle: string;
  hasSignatureConsent: boolean;
}

const initialValues: SignatureFormValues = {
  name: '',
  signatureStyle: '',
  hasSignatureConsent: false,
};

class SignatureForm extends React.Component<
  SignatureFormProps & InjectedIntlProps,
  SignatureFormState
> {
  state = { name: '' };

  signatureSchema = Yup.object().shape({
    name: Yup.string().required(
      this.props.intl.formatMessage({ id: 'form.card.field.validation.name' })
    ),
    signatureStyle: Yup.string()
      .nullable()
      .required(
        this.props.intl.formatMessage({
          id: 'form.card.field.validation.signatureStyle',
        })
      ),
    hasSignatureConsent: Yup.bool()
      .test(
        'hasSignatureConsent',
        this.props.intl.formatMessage({
          id: 'form.card.field.validation.hasSignatureConsent',
        }),
        value => value === true
      )
      .required(
        this.props.intl.formatMessage({
          id: 'form.card.field.validation.hasSignatureConsent',
        })
      ),
  });

  handleSubmit = async (
    values: SignatureFormValues,
    actions: FormikActions<SignatureFormValues>
  ) => {
    //process form submission here
    await this.props.onSubmit({
      name: values.name,
      signatureStyle: values.signatureStyle,
      hasSignatureConsent: values.hasSignatureConsent,
    });
    //done submitting, set submitting to false
    actions.setSubmitting(false);
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.target.value });
  };

  renderForm = (formikProps: FormikProps<SignatureFormValues>) => {
    const signatureOptions = this.props.fonts.map((font: Font) => {
      return {
        value: font.cssName,
        label: this.state.name ? this.state.name : font.displayName,
      };
    });

    const loaderColor = this.props.isInvertedColorScheme ? '#fff' : '#ef9a21';

    return (
      <Form className="signature pl-0">
        {this.props.errorMessage && (
          <StyledError message={this.props.errorMessage} />
        )}
        <div className="form-group">
          <label htmlFor="name">
            <FormattedHTMLMessage id="form.card.intro" />
          </label>
          <Field
            className="form-control"
            type="text"
            id="name"
            name="name"
            placeholder={this.props.intl.formatMessage({
              id: 'form.card.field.name',
            })}
            maxLength="70"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formikProps.handleChange(event);
              this.handleNameChange(event);
            }}
            disabled={formikProps.isSubmitting}
          />
          <ErrorMessage name="name" component={StyledError} />
        </div>

        <div className="form-group">
          {this.props.fontsErrorMessage && (
            <StyledError message={this.props.fontsErrorMessage} />
          )}
          <label htmlFor="signatureStyle">
            <FormattedMessage id="form.card.field.signatureStyle" />
          </label>
          <Field
            name="signatureStyle"
            id="signatureStyle"
            component={StyledSelect}
            options={signatureOptions}
            disabled={formikProps.isSubmitting}
          />
          <ErrorMessage name="signatureStyle" component={StyledError} />
        </div>

        <div className="form-check mb-3">
          <label className="form-check-label">
            <Field
              className="form-check-input"
              type="checkbox"
              name="hasSignatureConsent"
              checked={formikProps.values.hasSignatureConsent}
              disabled={formikProps.isSubmitting}
            />
            <FormattedHTMLMessage id="form.card.field.hasSignatureConsent" />
          </label>

          <ErrorMessage name="hasSignatureConsent" component={StyledError} />
        </div>

        <div className="d-flex flex-column flex-md-row">
          <div className="mb-2 mr-md-3">
            <Button
              id="signatureSubmitButton"
              type="submit"
              variant="secondary"
              disabled={formikProps.isSubmitting}
              style={{ minHeight: '38px' }}
            >
              <FormattedMessage id="form.card.submitButton" />{' '}
              {!formikProps.isSubmitting && <ArrowRightIcon />}
              {formikProps.isSubmitting && (
                <LoadingIndicator color={loaderColor} />
              )}
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={this.signatureSchema}
        onSubmit={this.handleSubmit}
        render={(formikProps: FormikProps<SignatureFormValues>) => {
          return this.renderForm(formikProps);
        }}
      />
    );
  }
}

export default injectIntl(SignatureForm);
