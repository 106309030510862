import React from 'react';
import { Comment } from '../api/Comment';
import { removeHTMLTags } from '../utils';
import { Container } from '@material-ui/core';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface SignatureBookProps {
  signatures: Comment[];
}

const SignatureBook: React.FC<SignatureBookProps> = ({ signatures }) => {
  const signatureItems = signatures.map(comment => (
    <span key={comment.id} className={removeHTMLTags(comment.content.rendered)}>
      {comment.author_name}{' '}
    </span>
  ));

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex align-items-between flex-wrap">
            {signatureItems}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignatureBook;
