import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import FooterLogo from './FooterLogo';

const Divider = styled.hr`
  border-top: 1px solid #ef9a21;
`;

const Footer: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  return (
    <footer className="App-footer py-3">
      <Container>
        <Row className="footer ml-3">
          <Col
            xs={12}
            sm={6}
            lg={8}
            className="d-flex flex-column flex-lg-row align-items-lg-center align-items-start justify-content-lg-start justify-content-between pl-3 pl-lg-5 "
          >
            <a
              href={intl.formatMessage({ id: 'footer.nav.about.url' })}
              target="_blank"
              rel="noreferrer noopener"
              hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
            >
              <FormattedMessage id="footer.nav.about" />
            </a>
            <a
              href={intl.formatMessage({ id: 'footer.nav.visit.url' })}
              target="_blank"
              rel="noreferrer noopener"
              hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
            >
              <FormattedMessage id="footer.nav.visit" />
            </a>
            <a
              href={process.env.REACT_APP_ALT_LANG_DOMAIN}
              rel="alternate"
              hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
            >
              <FormattedMessage id="nav.language" />
            </a>
          </Col>
          <Col xs={12} sm={6} lg={3} className="d-flex justify-content-md-end">
            <FooterLogo />
          </Col>
        </Row>
      </Container>
      <Divider />
      <Container className="sub-footer">
        <Row>
          <Col
            md={12}
            lg={7}
            className="d-flex flex-column flex-md-row align-items-md-center align-items-start justify-content-lg-start justify-content-between pl-3 pl-lg-5"
          >
            <p className="mb-0">
              <FormattedMessage id="footer.sub.text" />
              <br />
              <br />
              <FormattedMessage id="footer.sub.text.info" />
            </p>
          </Col>

          <Col
            md={12}
            lg={4}
            className="d-flex flex-row align-items-center align-content-center flex-wrap justify-content-lg-end mt-3 mt-lg-0 mx-auto"
          >
            <a
              href={intl.formatMessage({ id: 'footer.nav.privacy.url' })}
              target="_blank"
              rel="noreferrer noopener"
              hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
            >
              <FormattedMessage id="footer.nav.privacy" />
            </a>
            <a
              href={intl.formatMessage({ id: 'footer.nav.security.url' })}
              target="_blank"
              rel="noreferrer noopener"
              hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
            >
              <FormattedMessage id="footer.nav.security" />
            </a>
            <a
              href={intl.formatMessage({ id: 'footer.nav.terms.url' })}
              target="_blank"
              rel="noreferrer noopener"
              hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
            >
              <FormattedMessage id="footer.nav.terms" />
            </a>
            <a
              href={intl.formatMessage({ id: 'footer.nav.legal.url' })}
              target="_blank"
              rel="noreferrer noopener"
              hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
            >
              <FormattedMessage id="footer.nav.legal" />
            </a>
            <a
              href={intl.formatMessage({ id: 'footer.nav.accessibility.url' })}
              target="_blank"
              rel="noreferrer noopener"
              hrefLang={intl.formatMessage({ id: 'app.altLocale' })}
            >
              <FormattedMessage id="footer.nav.accessibility" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default injectIntl(Footer);
