import React from 'react';
import { FieldProps } from 'formik';
import Select from 'react-select';
import { SelectComponentsProps } from 'react-select/base';
import { Option } from 'react-select/src/filters';
import { InjectedIntlProps, injectIntl } from 'react-intl';

const customStyles = {
  option: (provided: any, state: any) => {
    // console.log({ provided, state });
    const isTooSmall = state.data.value === 'VladimirScriptLTStd';
    const newStyles = { ...provided, color: '#000' };
    if (isTooSmall) {
      return {
        ...newStyles,
        fontFamily: state.data.value,
        fontSize: '1.5em',
        lineHeight: '1em',
      };
    }
    return {
      ...newStyles,
      fontFamily: state.data.value,
    };
  },
  control: (provided: any) => {
    return {
      ...provided,
      backgroundColor: '#CDE0F3',
    };
  },
  dropdownIndicator: (provided: any) => {
    return {
      ...provided,
      color: '#ef9a21',
      '&:hover': {
        color: '#b6710d',
      },
    };
  },
  singleValue: (provided: any, state: any) => {
    const isTooSmall = state.data.value === 'VladimirScriptLTStd';
    if (isTooSmall) {
      return {
        ...provided,
        fontFamily: state.data.value,
        fontSize: '1.5em',
        lineHeight: '1em',
      };
    }
    return { ...provided, fontFamily: state.data.value };
  },
};

const StyledSelect: React.FunctionComponent<
  SelectComponentsProps & FieldProps & InjectedIntlProps
> = ({ options, field, form, intl, id }) => {
  const value = options.find((option: Option) => option.value === field.value);

  return (
    <Select
      inputId={id}
      name={field.name}
      placeholder={intl.formatMessage({
        id: 'form.card.field.signatureStyle.placeholder',
      })}
      isSearchable={false}
      options={options}
      value={value ? value : null}
      onChange={(option: any) => form.setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
      styles={customStyles}
    />
  );
};

export default injectIntl(StyledSelect);
