import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import Button from 'react-bootstrap/Button';
// import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const HeroCTA = styled.div`
  background: #01305c;
  color: white;
  padding: 0.7em 2em 1em 2em;

  @media (max-width: 768px) {
    padding: 0.7em 1em 0.5em 1 em;
  }
`;

const Hero: React.FunctionComponent<{ onButtonClick: () => void }> = props => {
  return (
    <div className="hero-block">
      <Container className="position-relative">
        <Row>
          <Col lg={{ span: 6, offset: 5 }} md={{ span: 8, offset: 4 }}>
            <HeroCTA>
              <h1 className="mt-2">
                <FormattedMessage id="hero.title" />
              </h1>
              <p className="mt-2">
                <FormattedHTMLMessage id="hero.intro" />
              </p>
              {props.children}
            </HeroCTA>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
