import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import styled from 'styled-components';

const Wrap = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    width: 75% !important;
  }
`;

const VideoBlock: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  return (
    <Wrap className={'embed-responsive embed-responsive-16by9 mx-auto'}>
      <iframe
        title={intl.formatMessage({ id: 'videoBlock.iframe.title' })}
        width="560"
        height="315"
        src={intl.formatMessage({ id: 'videoBlock.src' })}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </Wrap>
  );
};

export default injectIntl(VideoBlock);
