import React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { FormattedMessage } from 'react-intl';

const TestimonialConfirmation: React.FunctionComponent = () => {
  return (
    <p className="text-success font-weight-bold">
      <CheckCircle />
      <FormattedMessage id="form.testimonial.confirmation">
        {(...content) => <span id="storySubmittedConfirmation">{content}</span>}
      </FormattedMessage>
    </p>
  );
};

export default TestimonialConfirmation;
