import * as Sentry from '@sentry/browser';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IntlProvider, addLocaleData } from 'react-intl';
import englishLocaleData from 'react-intl/locale-data/en';
import frenchLocalData from 'react-intl/locale-data/fr';
import enText from './locales/en.json';
import frText from './locales/fr.json';

const locale =
  process.env.REACT_APP_LOCALE ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  'en';

const environment = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV;

Sentry.init({
  dsn: 'https://be5061b8f6024656826623a6a604a660@sentry.io/1530937',
  release:
    process.env.REACT_APP_NAME +
    '@' +
    process.env.REACT_APP_VERSION +
    ':' +
    environment +
    ':' +
    locale,
  environment: environment,
});

const translations: { [key: string]: any } = {
  en: enText,
  fr: frText,
};

addLocaleData([...englishLocaleData, ...frenchLocalData]);

const loader = document.querySelector('.loader');
const loading = document.querySelector('.loading');

const showLoader = () => {
  loader && loader.classList.remove('loader--hide');
};

const hideLoader = () => {
  loader && loader.classList.add('loader--hide');
  loading && loading.classList.remove('loading');
};

ReactDOM.render(
  <IntlProvider
    locale={locale}
    messages={translations[locale.split(/[-_]/)[0]]}
  >
    <App hideLoader={hideLoader} showLoader={showLoader} />
  </IntlProvider>,
  document.getElementById('root')
);

window.gtag('event', 'conversion', {
  allow_custom_scripts: true,
  send_to: process.env.REACT_APP_GOOGLE_TAG_ID_1 + '/md50c0/md_md0+standard',
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
