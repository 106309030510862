import React from 'react';
import AriaModal from 'react-aria-modal';
import Button from 'react-bootstrap/Button';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import CloseRounded from '@material-ui/icons/CloseRounded';

interface ModalProps {
  isMounted: boolean;
  applicationNodeId: string;
  onExit: () => void;
}

interface ModalState {
  hasEntered: boolean;
}

class Modal extends React.Component<
  ModalProps & InjectedIntlProps,
  ModalState
> {
  state = {
    hasEntered: false,
  };

  getApplicationNode = () => {
    return document.getElementById(this.props.applicationNodeId) as HTMLElement;
  };

  handleDeactivateModal = () => {
    this.setState(
      {
        hasEntered: false,
      },
      () => {
        setTimeout(() => {
          this.props.onExit();
        }, 300);
      }
    );
  };

  handleModalEnter = () => {
    this.setState({ hasEntered: true });
  };

  render() {
    let dialogContentClass = 'aria-modal aria-modal--animated';
    let underlayClass = 'underlay';
    if (this.state.hasEntered) {
      dialogContentClass += ' has-entered';
      underlayClass += ' has-entered';
    }
    return (
      <AriaModal
        titleText={this.props.intl.formatMessage({
          id: 'signatureModal.title',
        })}
        onEnter={this.handleModalEnter}
        onExit={this.handleDeactivateModal}
        initialFocus="#signature-modal-deactivate"
        getApplicationNode={this.getApplicationNode}
        mounted={this.props.isMounted}
        dialogClass="aria-dialog"
        underlayClass={underlayClass}
        underlayStyle={{ paddingTop: '2em' }}
      >
        <div id="signature-modal" className={dialogContentClass}>
          <div className="aria-modal-header">
            <Button
              id="signature-modal-deactivate"
              variant="primary"
              size="sm"
              onClick={this.handleDeactivateModal}
            >
              <CloseRounded />
            </Button>
            <h1>
              <FormattedMessage id="hero.title" />
            </h1>
          </div>
          <div className="aria-modal-body">{this.props.children}</div>
          <footer className="aria-modal-footer">
            <Button
              variant="primary"
              size="sm"
              onClick={this.handleDeactivateModal}
            >
              <FormattedMessage id="signatureModal.close" />
            </Button>
          </footer>
        </div>
      </AriaModal>
    );
  }
}

export default injectIntl(Modal);
