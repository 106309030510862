import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: inline-block;
  margin: 0 auto;
  margin-left: 8px;
`;

const LoadingIndicator: React.FunctionComponent<{ color: string }> = ({
  color,
}) => {
  return (
    <PulseLoader
      css={override}
      sizeUnit={'px'}
      size={5}
      color={color}
      // loading={loading}
    />
  );
};

export default LoadingIndicator;
